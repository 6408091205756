<template>
	<div class="page-box">
		<div class="search-head-main">
			<div class="search-head-item">
				<div class="nav-back-item gestures" @click="goBack">
					<img src="../../assets/back-icons.png"/>
					<span>返回</span>
				</div>
				<div class="search-keywords">关于<span>{{keyword}}</span>相关壁纸</div>
			</div>
			<div class="search-head-item">
				<div class="search-head-tab gestures">动态壁纸</div>
				<div class="tab-lines">丨</div>
				<div class="search-head-tab gestures">动态壁纸</div>
			</div>
			<div class="search-head-item">
				<div class="screen-item">
					<img  src="../../assets/sidebar/hot-tuijkian.png"/>
					<div class="screen-item-text">热门推荐</div>
				</div>
				<div class="screen-lines">丨</div>
				<div class="screen-item">
					<img  src="../../assets/sidebar/new-shengxin.png"/>
					<div class="screen-item-text" style="color: #333333;">最近上新</div>
				</div>
			</div>
		</div>
		<productItem></productItem>
	</div>
</template>

<script>
	import productItem from "@/components/productItem.vue"
	export default {
		components:{productItem},
		data() {
			return {
				keyword:''
			}
		},
		watch:{
			'$store.state.keywordVal':{
				handler(val) {
					if(val){
						this.keyword = val
					}
				}
			},
		},
		activated() {
			this.keyword = this.$route.query.keyword
		},
		created() {
			
		},
		methods:{
			goBack(){
				this.$router.back()
			},
		}
	}
</script>

<style lang="scss">
	.nav-back-item{
		display: flex;
		align-items: center;
		width: 56px;
		height: 28px;
		background-color: #F7F8F8;
		border-radius: 5px;
		justify-content: center;
		img{
			width: 12px;
			height: 9px;
		}
		span{
			color: #545454;
			font-size: 12px;
			margin-left: 4px;
		}
	}
	.screen-item{
		display: flex;
		align-items: center;
		img{
			width: 14px;
			height: 14px;
		}
		.screen-item-text{
			color: #FF5F61;
			font-size: 12px;
			margin-left: 5px;
		}
	}
	.screen-lines{
		margin: 0px 5px;
		color: #8A9297;
		font-size: 12px;
	}
	.search-head-tab{
		color: #727C8E;
		font-size: 12px;
	}
	.tab-lines{
		margin: 0px 10px;
	}
	.page-box{
		padding: 0px 16px;
		background-color: #ffffff;
	}
	.search-head-main{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 16px;
	}
	.search-head-item{
		display: flex;
		align-items: center;
		.search-keywords{
			margin-left: 14px;
			color: #4D5571;
			font-size: 12px;
			span{
				color: #FF5F61;
				font-weight: bold;
				margin: 0px 4px;
			}
		}
	}
	.page-backs{
		background: #F7F8F8;
		border-radius: 5px;
		padding: 6px 10px;
		color: #545454;
		font-size: 12px;
		
	}
</style>